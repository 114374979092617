<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="urunler"
      :line-numbers="true"
      :pagination-options="{
        enabled: true,
        perPage: 10,
        position: 'bottom',
        perPageDropdown: [5, 10, 20, 30, 50, 70, 100],
        dropdownAllowAll: true,
        firstLabel: 'İlk Sayfa',
        lastLabel: 'Son Sayfa',
        nextLabel: 'İleri',
        prevLabel: 'Geri',
        rowsPerPageLabel: 'Sayfa Başı Satır Sayısı',
        ofLabel: 've',
        pageLabel: 'Sayfa', // for 'pages' mode
        allLabel: 'Tümü',
      }"
      :search-options="{
        enabled: true,
        trigger: 'enter',
        placeholder: 'Tur Ara',
      }"
      @on-search="onSearchFilter"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :isLoading="isLoading"
      :totalRows="count"
      :sort-options="{
        enabled: false,
      }"
      styleClass="vgt-table striped bordered"
    >
      <!-- <template slot="loadingContent"> Test </template> -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'urun_tipi'">
          <span class="font-weight-bold"> {{ props.row.urun_tip }}</span>
        </span>
        <span v-else-if="props.column.field === 'urun_bilgi'">
          <span class="bg-primary text-white d-block p-1"> {{ props.row.baslik }}</span>
          <span class="bg-secondary text-white d-block p-1"> {{ props.row.k_no }}</span>
          <span class="bg-secondary text-white d-block p-1"> {{ fiyatTuruText(props.row.fiyat_turu) }}</span>
          <span class="bg-secondary text-white d-block p-1"> {{ fiyatlandirmaText(props.row.fiyatlandirma) }}</span>
          <span class="bg-secondary text-white d-block p-1"> {{ fiyatKisiAracText(props.row.fiyat_kisi_surucu) }}</span>
        </span>
        <span v-else-if="props.column.field === 'fiyat_maliyet'">
          <table class="table table-sm table-striped">
            <thead>
              <th class="align-middle">
                <b>{{ props.row.fiyat_turu == 'donemsel' ? 'Dönem' : 'Bölge' }}</b>
              </th>
              <th style="width: 260px" class="align-middle">
                <b>Fiyat</b>
                <small style="font-size: 10px" v-if="props.row.fiyatlandirma == 'tekil'">( Yetişkin | Çocuk | Bebek )</small>
                <small style="font-size: 10px" v-else>( En Az | En Cok | Fiyat | Arti Kişi )</small>
              </th>
              <th style="width: 250px" class="align-middle">
                <b class="mr-1">Maliyet</b>
                <small style="font-size: 10px" v-if="props.row.fiyatlandirma == 'tekil'">( Yetişkin | Çocuk | Bebek )</small>
                <small style="font-size: 10px" v-else>( Fiyat | Arti Kişi )</small>
              </th>
              <th class="text-right align-middle">
                <b-button class="rounded-0" size="sm" variant="success" @click="handlerUrunSatisFiyatEkle(props.row.originalIndex)">
                  <i class="fad fa-plus"></i>
                </b-button>
              </th>
            </thead>
            <tbody>
              <UrunMaliyetSatisFiyatItem
                v-for="(item, index) in props.row.satis_fiyat"
                :key="index"
                :item="item"
                :form="props.row"
                :number="index"
                @remove="handlerRemoveSatisFiyat(props.row.originalIndex, $event)"
              />
            </tbody>
          </table>
        </span>

        <span v-else-if="props.column.field === 'action'" class="float-right px-1">
          <b-button-group size="sm" class="pb-25">
            <b-button variant="success" class="rounded-0" @click="handlerUpdate(props.row)">
              <i class="fad fa-pencil" />
            </b-button>
          </b-button-group>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom">
        <!-- <Pagination
                :count="count"
                @handlerPageSizeChange="handlerPageSizeChange($event)"
                @handlerPageChange="handlerPageChange($event)"
              /> -->
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import store from '@/store';
import vSelect from 'vue-select';
import { useRouter } from '@/libs/utils';
import { VueGoodTable } from 'vue-good-table';
import { useToast } from 'vue-toastification/composition';
import { computed, defineComponent, onMounted, watch, ref, toRefs } from '@vue/composition-api';
import UrunMaliyetSatisFiyatItem from './component/UrunMaliyetSatisFiyatItem.vue';
export default defineComponent({
  components: { vSelect, VueGoodTable, UrunMaliyetSatisFiyatItem },
  setup(_, context) {
    const expo = {};
    const { router, route } = useRouter();
    const toast = useToast();
    expo.defaultDil = computed(() => store.getters.getDilAyarlari.varsayilan);
    expo.urunler = ref([]);
    expo.count = ref(0);
    expo.isLoading = ref(false);

    expo.columns = ref([
      {
        label: 'Ürün Tipi',
        field: 'urun_tipi',
        width: '7%',
      },
      {
        label: 'Ürün Bilgi',
        field: 'urun_bilgi',
        width: '26%',
      },

      {
        label: 'Fiyat Maliyet',
        field: 'fiyat_maliyet',
      },

      {
        label: 'İşlemler',
        field: 'action',
        width: '4%',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]);

    expo.fiyatTuruText = computed(() => {
      const statusText = {
        bolgesel: 'Bölgesel Fiyatlandırma',
        donemsel: 'Dönemsel Fiyatlandırma',
      };
      return (status) => statusText[status];
    });

    expo.fiyatlandirmaText = computed(() => {
      const statusText = {
        tekil: 'Tekil Fiyat',
        grup: 'Grup Fiyat',
      };
      return (status) => statusText[status];
    });

    expo.fiyatKisiAracText = computed(() => {
      const statusText = {
        kisi: 'Kişi Fiyat',
        arac: 'Araç Fiyat',
      };
      return (status) => statusText[status];
    });

    expo.handlerFetchAllData = async () => {
      expo.isLoading.value = true;
      context.emit('show', true);
      context.emit('pageTitle', 'Ürün Maliyet Fiyat');

      await store.dispatch('urunMaliyetFiyatListele', { urun_tipi: 'tur', lang: expo.defaultDil.value }).then((res) => {
        expo.urunler.value = res.data.data;
        expo.count.value = res.data.data.length;
        context.emit('total', `${expo.count.value} kayıttan ${expo.urunler.value.length} `);
        context.emit('show', false);
        expo.isLoading.value = false;
      });
    };

    expo.handlerFetchAllData();

    expo.onSearchFilter = (event) => {
      const { searchTerm } = event;
      serverParams.value.acente_adi = searchTerm;
      expo.handlerFetchAllData();
    };

    expo.onPageChange = (event) => {
      serverParams.value.page = event.currentPage;
      expo.handlerFetchAllData();
    };

    expo.onPerPageChange = (event) => {
      serverParams.value.page_size = event.currentPerPage;
      expo.handlerFetchAllData();
    };

    expo.handlerUpdate = (payload) => {
      context.emit('show', true);
      store.dispatch('urunMaliyetFiyatGuncelle', payload).then((res) => {
        if (res.data.success) {
          toast.success('Fiyat güncelleme başarılı.', {
            position: 'bottom-left',
          });
        }
        context.emit('show', false);
      });
    };

    expo.handlerUrunSatisFiyatEkle = (index) => {
      const urun = expo.urunler.value[index];
      if (urun.urun_tipi == 'tur' && urun.fiyatlandirma == 'tekil') {
        expo.urunler.value[index].satis_fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          fiyat: { yetiskin: 0, cocuk: 0, bebek: 0 },
          // maliyet: { yetiskin: 0, cocuk: 0, bebek: 0 },
        });
      } else if (urun.urun_tipi == 'tur' && urun.fiyatlandirma == 'grup') {
        expo.urunler.value[index].satis_fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          fiyat: { en_az: 1, en_cok: 0, fiyat: 0, arti_fiyat: 0 },
          // maliyet: { fiyat: 0, arti_fiyat: 0 },
        });
      } else {
        expo.urunler.value[index].satis_fiyat.push({
          bolge: null,
          bas_tarih: null,
          bit_tarih: null,
          fiyat: 0,
          // maliyet: 0,
        });
      }
    };

    expo.handlerRemoveSatisFiyat = (row, index) => {
      expo.urunler.value[row].satis_fiyat.splice(index, 1);
    };

    return { ...expo };
  },
});
</script>

<style scoped></style>
