import { render, staticRenderFns } from "./UrunMaliyetSatisFiyatItem.vue?vue&type=template&id=70786d8e&scoped=true&"
import script from "./UrunMaliyetSatisFiyatItem.vue?vue&type=script&lang=js&"
export * from "./UrunMaliyetSatisFiyatItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70786d8e",
  null
  
)

export default component.exports