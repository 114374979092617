<template>
  <tr>
    <td>
      <validation-provider name="Bölge" :rules="{ required: true }" v-if="form.bolge.length && form.fiyat_turu == 'bolgesel'">
        <v-select
          v-model="item.bolge"
          :options="form.bolge"
          :reduce="(fiyat) => fiyat.k_no"
          label="baslik"
          :clearable="true"
          multiple
          class="invoice-filter-select select-size-sm d-block"
          style="min-width: 100px; max-width: 100%"
        >
          <template slot="selected-option" slot-scope="option">
            {{ option.baslik }}
          </template>
          <template slot="option" slot-scope="option">
            {{ option.baslik }}
          </template>
        </v-select>
      </validation-provider>
      <validation-provider name="Tarih" :rules="{ required: true }" v-else>
        <flat-pickr
          v-model="date"
          class="form-control rounded-0 bg-white form-control-sm"
          :config="config"
          :events="config.events"
          @on-change="handlerOnChangeDate($event)"
        />
      </validation-provider>
    </td>
    <td>
      <b-input-group class="mb-2" v-if="form.urun_tipi != 'tur'">
        <b-form-input type="number" class="rounded-0 text-right" size="sm" v-model="item.fiyat" v-b-tooltip.hover.top="'Fiyat'" />
      </b-input-group>

      <b-input-group class="mb-2" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.yetiskin"
          v-b-tooltip.hover.top="'Yetişkin Fiyat'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.cocuk"
          v-b-tooltip.hover.top="'Çocuk Fiyat'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.bebek"
          v-b-tooltip.hover.top="'Bebek Fiyat'"
        />
      </b-input-group>

      <b-input-group class="mb-2" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'">
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.en_az"
          v-b-tooltip.hover.top="'Kap. En Az'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.en_cok"
          v-b-tooltip.hover.top="'Kap. En Çok'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.fiyat"
          v-b-tooltip.hover.top="'Grup Fiyat'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.fiyat.arti_kisi"
          v-b-tooltip.hover.top="'Artı Kişi Fiyat'"
        />
      </b-input-group>
    </td>
    <td>
      <b-input-group class="mb-2" v-if="form.urun_tipi != 'tur'">
        <b-form-input type="number" class="rounded-0 text-right" size="sm" v-model="item.maliyet" v-b-tooltip.hover.top="'Maliyet Fiyat'" />
      </b-input-group>

      <b-input-group class="mb-2" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'tekil'">
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.maliyet.yetiskin"
          v-b-tooltip.hover.top="'Yetişkin Maliyet'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.maliyet.cocuk"
          v-b-tooltip.hover.top="'Çocuk Maliyet'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.maliyet.bebek"
          v-b-tooltip.hover.top="'Bebek Maliyet'"
        />
      </b-input-group>

      <b-input-group class="mb-2" v-else-if="form.urun_tipi == 'tur' && form.fiyatlandirma == 'grup'">
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.maliyet.fiyat"
          v-b-tooltip.hover.top="'Grup Fiyat Maliyet'"
        />
        <b-form-input
          type="number"
          class="rounded-0 text-right"
          size="sm"
          v-model="item.maliyet.arti_fiyat"
          v-b-tooltip.hover.top="'Arti Kişi Maliyet'"
        />
      </b-input-group>
    </td>
    <td class="text-center align-middle">
      <b-button class="rounded-0" size="sm" variant="danger" @click="$emit('remove', number)">
        <i class="fad fa-trash"></i>
      </b-button>
    </td>
  </tr>
</template>

<script>
import vSelect from 'vue-select';
import { ref, defineComponent, computed, toRefs, watch } from '@vue/composition-api';

import moment from 'moment';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
export default defineComponent({
  components: { vSelect, flatPickr },
  props: {
    number: [Number],
    item: { type: Object, required: true },
    form: { type: Object, required: true },
    defaultDil: [String],
  },
  setup(props) {
    const expo = {};
    const { item, form } = toRefs(props);
    expo.fiyatBolgeleri = ref([]);
    expo.date = ref(null);
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      showMonths: 2,
      mode: 'range',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
      allowInput: true,
    });

    expo.handlerOnChangeDate = (event) => {
      if (event.length > 0) {
        item.value.bas_tarih = moment(String(event[0])).valueOf();
        item.value.bit_tarih = moment(String(event[1])).valueOf();
      }
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    watch(
      item.value,
      (val) => {
        expo.date.value = moment(val.bas_tarih).format('DD.MM.YYYY') + ' - ' + moment(val.bit_tarih).format('DD.MM.YYYY');

        if (val.maliyet == undefined) {
          if (form.value.urun_tipi != 'tur') {
            item.value.maliyet = 0;
          } else if (form.value.urun_tipi == 'tur' && form.value.fiyatlandirma == 'tekil') {
            item.value.maliyet = { yetiskin: 0, cocuk: 0, bebek: 0 };
          } else if (form.value.urun_tipi == 'tur' && form.value.fiyatlandirma == 'grup') {
            item.value.maliyet = { fiyat: 0, arti_fiyat: 0 };
          }
        }
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
